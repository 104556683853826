import JsSIP from 'jssip';
import { useState } from 'react';
import { getExperIpCredentials } from 'util/isAdmin';

export const useSipAuth = ( setDialerStatus ) => {
	const [ authSuccess, setAuthSuccess ] = useState( false );
	const [ authError, setAuthError ] = useState( '' );
	const socket = new JsSIP.WebSocketInterface( 'wss://acordofechado.experip.com.br/ws' );
	const credentials = getExperIpCredentials();

	async function ami( { token, action, family, key, val } ) {
		await fetch( 'https://acordofechado.experip.com.br/api/ami', {
			method  : 'POST',
			headers : {
				'Content-Type'  : 'application/json',
				'Authorization' : 'Bearer ' + token
			},
			body: JSON.stringify( {
				Action : action,
				Family : family,
				Key    : key,
				Val    : val
			} )
		} );
	}
	async function Login() {
		return await fetch( 'https://acordofechado.experip.com.br/api/token', {
			method  : 'POST',
			headers : {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify( {
				username : credentials?.voip_user ?? '',
				password : credentials?.voip_password ?? '',
			} )
		} )
			.then( response => response.json() )
			.then( async data => {
				localStorage.setItem( 'token-experip', data?.token );
				if ( data?.error ) {
					localStorage.setItem( 'experip-token-error', 0 );
					setAuthError( data?.error );
					return setAuthSuccess( false );
				}
				setAuthSuccess( true );
				await statusUpdate( data.token, data.user );
				await queueAuths( data.token, data.user );
				const configuration = {
					sockets           : [ socket ],
					uri               : `sip:${data.user?.Peer?.username}@acordofechado.experip.com.br`,
					password          : data.user?.Peer?.secret,
					no_answer_timeout : 300,
				};
				let ua = new JsSIP.UA( configuration );
				ua.on( 'registered', () => {
					console.log( 'Softphone registered!' );
					setDialerStatus( true );
				} );
				ua.start();
				return ua;
			} );
	}

	async function statusUpdate( token, user ) {
		await fetch( 'https://acordofechado.experip.com.br/api/statusUpdate', {
			method  : 'PUT',
			headers : {
				'Content-Type'  : 'application/json',
				'Authorization' : 'Bearer ' + token //localStorage.getItem( 'token-experip' )
			}, body: JSON.stringify( {
				srcId  : user.id,
				status : 'Online',
				type   : 'user'
			} ) } );
	}

	async function queueAuths( token, user ) {
		if ( !user.Peer ) {
			setAuthSuccess( false );
			return setAuthError( `Ramal não configurado para o usuário ${user.username}` );
		}
		await fetch( 'https://acordofechado.experip.com.br/api/queuesAuths', {
			method  : 'POST',
			headers : {
				'Content-Type'  : 'application/json',
				'Authorization' : 'Bearer ' + token //localStorage.getItem( 'token-experip' )
			},
			body: JSON.stringify( {
				direction : 'outgoing',
				peerId    : user?.Peer?.id,
				penalty   : 0,
				queueId   : 2,
				userId    : user?.id
			} )
		} )
			.then( response => response.json() )
			.then( async () => {
				const FILA_SAIDA_ATIVO = 2;
    			await ami( { token, action: 'DbPut', family: 'queueout', key: user?.Peer?.username, val: FILA_SAIDA_ATIVO } );
			} );
	}


	async function authenticateSIP() {
		if ( localStorage.getItem( 'experip-token-error' ) === 0 && localStorage.getItem( 'experip-token-error' ) !== null ) {
			return setAuthError( 'Autenticação bloqueada, entre em contato com o suporte.' );
		}
		return await Login();
	}
	return {
		authenticateSIP,
		authSuccess,
		authError,
	};

};