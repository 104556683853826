import React, { useEffect } from 'react';
import { DialerClosedInteractible } from './styles';
import { HeadsetMic } from '@material-ui/icons';
import { useDialerContext } from '../DialerContext';

const ClosedDialer = ( { handleOpen, firstRender, setFirstRender } ) => {
	const { 
		authenticateSIP,
		setController,
		setLoading
	} = useDialerContext();

	useEffect( () => {
		if ( firstRender ) {
			authenticateSIP().then( ( data ) => {
				setLoading( false );
				setController( data );
				setFirstRender( false );
			} );
		}
	}, [] ); //eslint-disable-line
	
	return (
		<DialerClosedInteractible onClick={handleOpen}>
			<HeadsetMic fontSize='large' />
		</DialerClosedInteractible>
	);
};

export default ClosedDialer;