import React, { useContext, useState } from 'react';

import { createContext } from 'react';
import { useSipAuth } from './hooks/useSipAuth';
import { useDialerComponentHandler } from './hooks/useComponentHandler';
import { useSessionHandlers } from './hooks/useSessionHandlers';
import { phoneMask } from 'components/Mask';
import { useNotifyAlert } from 'context/NotifyAlert';

const DialerContext = createContext( {} );

const defaultSessionStatus = { inProgress: false };

export const DialerContextProvider = ( { children } ) => {
	const [ session, setSession ] = useState( defaultSessionStatus );
	const [ dialerStatus, setDialerStatus ] = useState( false );
	const [ caseContext, setCaseContext ] = useState( {} );
	const [ callNumber, setCallNumber ] = useState( '' );
	const [ controller, setController ] = useState( {} );
	const [ isLoading, setLoading ] = useState( false );
	const { authenticateSIP, authSuccess, authError } = useSipAuth( setDialerStatus );
	const { isOpen, handleClose, handleOpen } = useDialerComponentHandler();
	const {
		callInProgress,
		eventHandlers,
		isMuted,
		setIsMuted,
		isPaused,
		setIsPaused,
		getTimerFormatted,
		isConnecting,
		pauseTimer,
		startTimer,
	} = useSessionHandlers( dialerStatus );
	const { setShowNotify } = useNotifyAlert();


	const handleCall = ( number ) => {
		setCallNumber( number );
		number = number.replace( /\s+/g, '' );
		const session = controller.call( number, {
			eventHandlers,
			mediaConstraints: {
				audio : true,
				video : false,
			},
		} );
		handleOpen();
		if ( session ) {
			session.connection.addEventListener( 'addstream', ( e ) => {
				var audio = document.createElement( 'audio' );
				audio.srcObject = e.stream;
				audio.play();
			} );
			setSession( session );
		} 
	};

	const handleHangup = () => {
		session.terminate();
		setSession( defaultSessionStatus );
	};

	const handleMute = () => {
		const muteOptions = session.isMuted();
		if ( !muteOptions?.audio ) {
			session.mute();
			setIsMuted( true );
		} else {
			session.unmute();
			setIsMuted( false );
		}
	};

	const handlePause = () => {
		const pausedOptions = session.isOnHold();
		if ( !pausedOptions?.local ) {
			session.hold();
			setIsPaused( true );
			pauseTimer();
		} else {
			session.unhold();
			setIsPaused( false );
			startTimer();
		}
	};

	const handleConnection = () => {
		if ( controller.status !== 1 ) {
			controller.start();
			setDialerStatus( true );
		} else {
			controller.stop();
			setDialerStatus( false );
			setSession( defaultSessionStatus );
		}
	};

	const handleChangePhoneNumber = ( num ) => {
		if ( num !== 'backspace' ) {
			return setCallNumber( ( prev ) => {
				return phoneMask( `${prev}${num}` );
			} );
		}
		setCallNumber( ( prev ) => {
			return prev.slice( 0, -1 );
		} );
	};

	const getError = () => {
		setShowNotify( {
			status : 'info',
			text   : 'Código do erro copiado!'
		} );
		navigator.clipboard.writeText( authError );
	};

	return (
		<DialerContext.Provider value={{
			//passar valores para o contexto
			controller,
			isLoading,
			handleCall,
			session,
			isOpen, 
			handleClose, 
			handleOpen,
			dialerStatus,
			setDialerStatus,
			handleConnection,
			callInProgress,
			handleHangup,
			setCaseContext,
			handleMute,
			handlePause,
			caseContext,
			callNumber,
			setCallNumber,
			isMuted,
			isPaused,
			handleChangePhoneNumber,
			getTimerFormatted,
			isConnecting,
			authSuccess,
			getError,
			authenticateSIP,
			setController,
			setLoading,
		}}>
			{children}
		</DialerContext.Provider>
	);
};

export function useDialerContext() {
	const context = useContext( DialerContext );

	if ( !context ) {
		throw new Error( 'Contexto não encontrado' );
	}

	return context;
}
